import * as React from "react"

import Layout from "../../templates/layout"
import Seo from "../../helpers/seo"
import GridSlider from "../../components/organisms/GridSlider"
import Slogan from "../../components/molecules/Slogan"

const Cases = () => {
  return (
    <Layout page="Cases">
      <Slogan>
        <b>Cases</b>
      </Slogan>
      <GridSlider />
    </Layout>
  )
}
export const Head = () => <Seo title="Cases" />

export default Cases
