import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import useStaticImage from '../../../hooks/useStaticImage'
import { Grid, Pagination, Navigation } from 'swiper'
import { navigate } from 'gatsby'

import information from './information.json'

import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'
import './styles.scss'
import { isMobile } from 'react-device-detect'

const GridSlider = () => {
  const [getImage] = useStaticImage()

  const [activeTab, setActiveTab] = useState('slider1')
  const [activeTitleTab, setActiveTitleTab] = useState('Todos os cases')
  const [activeDropDown, setActiveDropDown] = useState('close')

  const handleDrop = () => {
    activeDropDown === 'close' ? setActiveDropDown('open') : setActiveDropDown('close')
  }

  const handleTab = (tab) => {
    setActiveTab(tab)
  }

  const handleActiveClassTab = (tab) => {
    return activeTab === tab ? 'tabs__item--active' : 'tabs__item--disable'
  }

  const handleActiveClassContainer = (tab) => {
    return activeTab === tab ? `${tab} active` : `${tab}`
  }

  const GridSliderComponent = ({ children }) => {
    return (
      <Swiper
        allowTouchMove={!!isMobile}
        spaceBetween={20}
        slidesPerGroup={2}
        slidesPerView={2}
        grid={{
          rows: 3,
          cols: 2,
          fill: 'row',
        }}
        navigation={{}}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>'
          },
        }}
        modules={[Navigation, Grid, Pagination]}
        breakpoints={{
          500: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30,
            grid: {
              rows: 3,
              colws: 3,
              fill: 'row',
            },
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30,
            grid: {
              rows: 3,
              colws: 3,
              fill: 'row',
            },
          },
        }}
        className="mySwiper"
      >
        {children}
      </Swiper>
    )
  }

  const renderMenu = () => {
    const infos = [
      {
        slider: 'slider1',
        title: 'Todos os cases',
      },
      {
        slider: 'slider2',
        title: 'Projetos Web e Mobile',
      },
      {
        slider: 'slider3',
        title: 'Outsourcing',
      },
      {
        slider: 'slider4',
        title: 'Inovação',
      },
      {
        slider: 'slider5',
        title: 'AI Labs',
      },
    ]
    return infos.map((item) => {
      return (
        <li
          className={handleActiveClassTab(item.slider)}
          onClick={() => handleTab(`${item.slider}`)}
          onKeyDown={() => handleTab(`${item.slider}`)}
          role="none"
          key={item.slider}
        >
          <p>
            {item.title}
            <span></span>
          </p>
        </li>
      )
    })
  }

  const renderMenuMobile = () => {
    const infos = [
      {
        slider: 'slider1',
        title: 'Todos os cases',
        active: activeTab === 'slider1',
      },
      {
        slider: 'slider2',
        title: 'Projetos Web e Mobile',
        active: activeTab === 'slider2',
      },
      {
        slider: 'slider3',
        title: 'Outsourcing',
        active: activeTab === 'slider3',
      },
      {
        slider: 'slider4',
        title: 'Inovação',
        active: activeTab === 'slider4',
      },
      {
        slider: 'slider5',
        title: 'AI Labs',
        active: activeTab === 'slider5',
      },
    ]

    return infos.map((item) => {
      return (
        <li
          style={{ color: activeTab === item.slider && '#F05A22' }}
          className="mobile-tabs__list--item"
          onClick={() => {
            setActiveTab(item.slider)
            setActiveTitleTab(item.title)
            setActiveDropDown('close')
          }}
          onKeyDown={() => {
            setActiveTab(item.slider)
            setActiveTitleTab(item.title)
            setActiveDropDown('close')
          }}
          role="none"
          key={item.slider}
        >
          <p>{item.title}</p>
        </li>
      )
    })
  }

  return (
    <section className="gridslider">
      <div className="gridslider__container">
        <div className="gridslider__tabs">
          <ul className="tabs">{renderMenu()}</ul>

          <div style={{ position: 'relative' }}>
            <div className="mobile-tabs" onClick={handleDrop} onKeyDown={handleDrop}>
              {activeTitleTab}
              <span
                className={`mobile-tabs__caret ${
                  activeDropDown === 'open' && 'mobile-tabs__caret--opened'
                }`}
              />
            </div>
            {activeDropDown === 'open' && (
              <div className="mobile-tabs__list">{renderMenuMobile()}</div>
            )}
          </div>
        </div>
        <div className={handleActiveClassContainer('slider1')}>
          <GridSliderComponent>
            {
              information.filter((item, index, self) =>
                index === self.findIndex((t) => (
                  t.name === item.name
                ))
              ).map((item) => {
              return (
                <SwiperSlide
                  onClick={() => navigate(`${item?.text?.link}`)}
                  key={item.image}
                >
                  <div className="gridslider__item">
                    <GatsbyImage
                      style={{ width: '100%' }}
                      image={getImage(item.image)}
                      alt={`${item.name}`}
                    />
                    <div className="item-hover">
                      <div>
                        <p>{item.name}</p>
                        <p>{item.text.content}</p>
                        <p>Veja mais</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </GridSliderComponent>
        </div>
        <div className={handleActiveClassContainer('slider2')}>
          <GridSliderComponent>
            {information
              .filter((item) => item.category === 'slider2')
              .map((item) => {
                return (
                  <SwiperSlide
                    onClick={() => navigate(`${item?.text?.link}`)}
                    key={item.image}
                  >
                    <div className="gridslider__item">
                      <GatsbyImage
                        style={{ width: '100%' }}
                        image={getImage(item.image)}
                        alt={`${item.name}`}
                      />
                      <div className="item-hover">
                        <div>
                          <p>{item.name}</p>
                          <p>{item.text.content}</p>
                          <p>Veja mais</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
        <div className={handleActiveClassContainer('slider3')}>
          <GridSliderComponent>
            {information
              .filter((item) => item.category === 'slider3')
              .map((item) => {
                return (
                  <SwiperSlide
                    onClick={() => navigate(`${item?.text?.link}`)}
                    key={item.image}
                  >
                    <div className="gridslider__item">
                      <GatsbyImage
                        style={{ width: '100%' }}
                        image={getImage(item.image)}
                        alt={`${item.name}`}
                      />
                      <div className="item-hover">
                        <div>
                          <p>{item.name}</p>
                          <p>{item.text.content}</p>
                          <p>Veja mais</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
        <div className={handleActiveClassContainer('slider4')}>
          <GridSliderComponent>
            {information
              .filter((item) => item.category === 'slider4')
              .map((item) => {
                return (
                  <SwiperSlide
                    onClick={() => navigate(`${item?.text?.link}`)}
                    key={item.image}
                  >
                    <div className="gridslider__item">
                      <GatsbyImage
                        style={{ width: '100%' }}
                        image={getImage(item.image)}
                        alt={`${item.name}`}
                      />
                      <div className="item-hover">
                        <div>
                          <p>{item.name}</p>
                          <p>{item.text.content}</p>
                          <p>Veja mais</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
        <div className={handleActiveClassContainer('slider5')}>
          <GridSliderComponent>
            {information
              .filter((item) => item.category === 'slider5')
              .map((item) => {
                return (
                  <SwiperSlide
                    onClick={() => navigate(`${item?.text?.link}`)}
                    key={item.image}
                  >
                    <div className="gridslider__item">
                      <GatsbyImage
                        style={{ width: '100%' }}
                        image={getImage(item.image)}
                        alt={`${item.name}`}
                      />
                      <div className="item-hover">
                        <div>
                          <p>{item.name}</p>
                          <p>{item.text.content}</p>
                          <p>Veja mais</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
      </div>
    </section>
  )
}
export default GridSlider
